import request from '@/utils/request'


// 查询订单-订单主列表
export function listOrder(query) {
  return request({
    url: '/order/order/list',
    method: 'get',
    params: query
  })
}

// 查询订单-订单主分页
export function pageOrder(query) {
  return request({
    url: '/order/order/page',
    method: 'get',
    params: query
  })
}

// 查询订单-订单主详细
export function getOrder(data) {
  return request({
    url: '/order/order/detail',
    method: 'get',
    params: data
  })
}

// 新增订单-订单主
export function addOrder(data) {
  return request({
    url: '/order/order/add',
    method: 'post',
    data: data
  })
}

// 修改订单-订单主
export function updateOrder(data) {
  return request({
    url: '/order/order/edit',
    method: 'post',
    data: data
  })
}

// 删除订单-订单主
export function delOrder(data) {
  return request({
    url: '/order/order/delete',
    method: 'post',
    data: data
  })
}
// 备注订单-订单主
export function orderRemark(data) {
  return request({
    url: '/order/order/remark',
    method: 'post',
    data: data
  })
}

